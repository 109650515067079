.client-image {
    width: 80px; 
    border-radius: 50%;
    position: absolute; 
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
}
.h-75 {
    height: 75vh;;
}
