body {
    font-family: "Rubik", sans-serif;
}
.user-button {
    background-color: rgba(200, 200, 200, .2);
}
.cursor-pointer {
    cursor: pointer !important;
}
.dropdown-toggle::after {
    display: none;
}
.bg-starglass {
    background: rgb(13,28,39);
    background: linear-gradient(90deg, rgba(13,28,39,1) 0%, rgba(31,59,84,1) 50%, rgba(13,28,39,1) 100%);
    color: #fff;
}
.bg-gold {
    background: linear-gradient(90deg, rgba(221,207,133,1) 66%, rgba(108,91,42,1) 100%);
}
.today-orders {
    height: 83vh;
}

.empty-today-orders {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%; /* Adjust the height as needed, could be 100vh for full viewport height or another value */
    text-align: center;
  }
  
.empty-image img {
    width: 200px; /* You have this inline, but it could be moved to CSS */
    margin-bottom: 1rem; /* Optional space between image and text */
}

.main-page-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: 0.5s;
}
.main-page-button h2 {
    transition: 0.3s;
}
.main-page-button:hover {
    transform: scale(1.05);
}
.color-dark-blue {
    color: #314c67;
}
.color-light-blue {
    color: #9ccdd1;
}

.login-page-container {
    height: 100vh;
    background-image: url('./images/login-bg.jpg'); /* Replace with your image path */
    background-size: cover; /* Cover the entire area */
    background-position: center; /* Center the background image */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .form-container {
    background-color: rgba(180, 180, 180, 0.2); /* White with transparency */
    backdrop-filter: blur(5px); /* Blur effect */
  }
  
  @media (max-width: 768px) {
    .form-container {
      max-width: 90%; /* More responsive on smaller screens */
    }
  }

.modal-80w {
    max-width: 80%; /* Set your desired width here */
    width: 80%;
}